import { request } from "./index";
export function newsPage(params) {
  return request({
    url: "/yunch/newsPage",
    method: "get",
    params
  });
}
export function newsById(params) {
  return request({
    url: "/yunch/newsById",
    method: "get",
    params
  });
}
//全部新闻或案例
export function allNews(params) {
  return request({
    url: "/yunch/indexPage",
    method: "get",
    params
  });
}