<template>
  <div class="news-detail">
    <div class="main">
      <div class="wrapper" v-if="pageData.current.title">
        <h1 class="title">{{ pageData.current.title }}</h1>
        <div class="author">
          <!-- 分类 -->
          <div class="class">
            分类：
            <span>{{
            pageData.current.treeId == 13
            ? "公司新闻"
            : pageData.current.treeId == 11
            ? "产品新闻"
            : "行业新闻"
            }}</span>
          </div>
          <div class="class">
            发布时间：
            <span>{{
            pageData.current.publishDate &&
            pageData.current.publishDate.slice(0, 10)
            }}</span>
          </div>
          <div class="class">
            作者：
            <span>{{ pageData.current.author }}</span>
          </div>
        </div>
        <el-divider></el-divider>
        <article v-html="pageData.current.detail"></article>
        <div class="bottom">
          <div class="left">
            <span class="jump" style="cursor:pointer" @click="getData(pageData.pre.id)">上一页</span>
            <span class="url" @click="getData(pageData.pre.id)" :style="
              pageData.pre
                ? { borderBottom: '1px solid #000' }
                : { borderBottom: '1px solid transparent' }
            ">{{ pageData.pre !== null ? pageData.pre.title : "无" }}</span>
          </div>
          <div class="right">
            <span class="url" @click="getData(pageData.next.id)" :style="
              pageData.next
                ? { borderBottom: '1px solid #000' }
                : { borderBottom: '1px solid transparent' }
            ">{{ pageData.next !== null ? pageData.next.title : "无" }}</span>
            <span class="jump" style="cursor:pointer" @click="getData(pageData.next.id)">下一页</span>
          </div>
        </div>
      </div>
    </div>
    <div class="relative">
      <div class="wrapper">
        <h1 class="title">
          相关新闻
        </h1>
        <div class="item" v-for="item in pageData.relatedList" :key="item.id" @click="getData(item.id)">
          <img :src="item.coverUrl" alt="" class="relative_img"/>
          <div class="content">
            {{item.title}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { newsById } from "@/api/news.js";

export default {
  data() {
    return {
      pageData: {
        current: {
          treeId: null,
          title: "",
          summary: "",
          detail: ""
        },
        pre: {
          title: ""
        },
        next: {
          title: ""
        }
      }
    };
  },
  created() {
    this.id = this.$route.query.id
    this.categoryId = this.$route.query.categoryId
    this.getData();
  },
  methods: {
    async getData(id) {
      if (id) {
        this.id = id;
      }
      let params = {};
      if (this.categoryId == 1) {
        params = { id: this.id };
      } else {
        params = { id: this.id, categoryId: this.categoryId };
      }
      let { data: res } = await newsById(params);
      this.pageData = res.data;
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
